import React, { FC, useState } from "react";
import styles from "./SalesReportUpdate.module.css";
import MiniDrawer from "../../components/Sidebar";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteIcon from "@mui/icons-material/Delete";
import clientQuerystyles from "../clientQuery/clientQuery.styles";
import { useLocation, useNavigate } from "react-router-dom";
import salesReport from "./salesReport.styles";
const classes = salesReport;

const AddSalesReport: FC = () => {
  // route
  const navigate = useNavigate();

  function goToSalesReport() {
    navigate("/salesReport");
  }

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    Padding: "10px",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1, display: "flex", justifyContent: "center" }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography sx={classes.mainHead}>
                        Client Query
                    </Typography> */}
          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>

        <Stack>
          <Typography
            variant="h5"
            style={{
              fontWeight: "700",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "15px 10px",
            }}
          >
            Sales Report - Edit / View
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center", margin: "10px", padding: "10px" }}
            >
              {" "}
              <Button onClick={handleOpen}>
                <CloudUploadIcon
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "100px",
                    fontSize: "40px",
                  }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => console.log(event.target.files)}
                        multiple
                      />
                    </Button>

                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<FindReplaceIcon />}
                    >
                      Replace Image
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Image
                    </Button>
                  </Box>
                </Modal>
              </Button>
              <Typography>Upload Image</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Name of gym</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter id"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Unique id</label>
                <input
                  type="number"
                  className={styles.inputField}
                  placeholder="Enter id"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Plan</label>
                <select className={styles.selectField}>
                  <option value="">Select</option>
                  <option value="PLAN">Silver</option>
                  <option value="PLAN">Gold</option>
                  <option value="PLAN">Diamond</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Price</label>
                <input
                  type="number"
                  className={styles.inputField}
                  placeholder="Enter Price"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Total</label>
                <input
                  type="number"
                  className={styles.inputField}
                  placeholder="Enter Total"
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                marginTop: "20px",
                alignItems: "center",
                gap: "200px",
                // border:"2px solid black"
              }}
            >
              {" "}
              <div>
                <button
                  className={styles.cancelButton}
                  onClick={goToSalesReport}
                >
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  style={{ marginLeft: "10px" }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Stack>

        {/* <div className={styles.formContainer}>
                    <h2 className={styles.heading}>Sales Report - Edit / View</h2>
                    <div className={styles.formRow}>
                        <div className={styles.formGroup}>
                            <label className={styles.label}>Name of gym</label>
                            <select className={styles.selectField}>
                                <option value="">Select</option>
                                <option value="gym1">Gym 1</option>
                                <option value="gym2">Gym 2</option>
                            </select>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.label}>Unique ID</label>
                            <input type="text" className={styles.inputField} placeholder="Enter Unique ID" />
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.formGroup}>
                            <label className={styles.label}>Plan</label>
                            <select className={styles.selectField}>
                                <option value="">Select</option>
                                <option value="plan1">Plan 1</option>
                                <option value="plan2">Plan 2</option>
                            </select>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={styles.label}>Price</label>
                            <input type="text" className={styles.inputField} placeholder="Enter Price" />
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.formGroup} style={{ width: '100%' }}>
                            <label className={styles.label}>Total</label>
                            <input type="text" className={styles.inputField} placeholder="Enter Total" />
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button className={styles.cancelButton} onClick={goToSalesReport}>Cancel</button>
                        <button className={styles.saveButton}>Save</button>
                    </div>
                </div> */}
      </Box>
    </Box>
  );
};

export default AddSalesReport;
