import React, { FC, useState } from "react";
import styles from "../gymDetail/GymDetailUpdate.module.css";
import MiniDrawer from "../../components/Sidebar";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import salesReport from "../salesReport/salesReport.styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import urls from "../../global/urlConstant";

interface EditableGymData {
  _id: string;
  address: string;
  contact: string;
  emailAddress: string;
  gymName: string;
  expiresAt: string;
  photo: string;
  plan: "SILVER" | "GOLD" | "DIAMOND";
  subscription: string;
  uniqueId: string;
  userId: string;
  website: string;
  isExpiredSoon: boolean;
  createdAt: string;
  updatedAt: string;
}

const classes = salesReport;

const DashboardUpdate: FC = () => {
  const location = useLocation();
  const receivedData = location.state;

  console.log("receivedData", receivedData);

  const navigate = useNavigate();

  function goToDashboard() {
    navigate("/dashboard");
  }
  function gotoEditPage() {
    navigate("/dashboard");
  }

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    Padding: "10px",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // recieved data modify useState() hooks code starts here

  const [editableData, setEditableData] = useState(receivedData); // Create a local state from props

  const handleInputChange =
    (field: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setEditableData((prevData: EditableGymData) => ({
        ...prevData,
        [field]: event.target.value,
      }));
    };

  // for joining date change
  const handleDateChange = (event: any) => {
    setEditableData({ ...editableData, joiningDate: event.target.value }); // Update name
  };
  // for contact number change
  const handleContactChange = (event: any) => {
    setEditableData({ ...editableData, contact: event.target.value }); // Update name
  };
  // for email number change
  const handleEmailChange = (event: any) => {
    setEditableData({ ...editableData, email: event.target.value }); // Update name
  };
  // for expiry date
  const handleExpiryChange = (event: any) => {
    setEditableData({ ...editableData, expiryDate: event.target.value }); // Update name
  };
  // for status change
  const handleStatusChange = (event: any) => {
    setEditableData({ ...editableData, status: event.target.value }); // Update name
  };
  // for plan change
  const handlePlanChange = (event: any) => {
    setEditableData({ ...editableData, plan: event.target.value }); // Update name
  };

  // recieved data modify useState() hooks code ends here
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) return null;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const updatedGymDetails = {
      ...editableData,
      updatedAt: new Date().toISOString(),
    };

    try {
      await axios.put(
        `${urls.baseUrl}/api/gyms/${updatedGymDetails.uniqueId}`,
        updatedGymDetails
      );
      console.log("Update successful");
      navigate("/dashboard");
    } catch (error) {
      console.error("Update failed", error);
    }
  };
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1, display: "flex", justifyContent: "center" }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography sx={classes.mainHead}>
                        Client Query
                    </Typography> */}
          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>

        <Stack>
          <Typography
            variant="h5"
            style={{
              fontWeight: "700",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "15px 10px",
            }}
          >
            Dashboard - Edit / View
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center", margin: "10px", padding: "10px" }}
            >
              {" "}
              <Button onClick={handleOpen}>
                <CloudUploadIcon
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "100px",
                    fontSize: "40px",
                  }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event: { target: { files: any } }) =>
                          console.log(event.target.files)
                        }
                        multiple
                      />
                    </Button>

                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<FindReplaceIcon />}
                    >
                      Replace Image
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Image
                    </Button>

                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        zIndex: 1,
                      }}
                      onClick={gotoEditPage}
                      aria-label="Close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Modal>
              </Button>
              <Typography>Upload Image</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Name of gym</label>
                <input
                  type="text"
                  value={editableData.gymName}
                  className={styles.inputField}
                  placeholder="Enter Number"
                  onChange={handleInputChange("gymName")}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Date of Joining</label>
                <input
                  type="text"
                  value={editableData.createdAt}
                  className={styles.inputField}
                  placeholder="Enter Joining date"
                  onChange={handleInputChange("createdAt")}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Contact No.</label>
                <input
                  type="number"
                  value={editableData.contact}
                  className={styles.inputField}
                  placeholder="Enter Number"
                  onChange={handleInputChange("contact")}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Email</label>
                <input
                  type="Email"
                  value={editableData.emailAddress}
                  className={styles.inputField}
                  placeholder="Enter Email"
                  onChange={handleInputChange("emailAddress")}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Expiry date</label>
                <input
                  type="text"
                  value={editableData.expiresAt}
                  className={styles.inputField}
                  placeholder="Enter date"
                  onChange={handleInputChange("expiresAt")}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Status</label>
                <select
                  value={editableData.status}
                  className={styles.selectField}
                  onChange={handleInputChange("status")}
                >
                  <option value="">Select</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Plan</label>
                <select
                  value={editableData.plan}
                  className={styles.selectField}
                  onChange={handleInputChange("plan")}
                >
                  <option value="">Select</option>
                  <option value="PLAN">Silver</option>
                  <option value="PLAN">Gold</option>
                  <option value="PLAN">Diamond</option>
                </select>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                marginTop: "20px",
                alignItems: "center",
                gap: "200px",
                // border:"2px solid black"
              }}
            >
              {" "}
              <div>
                <button className={styles.cancelButton} onClick={goToDashboard}>
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

export default DashboardUpdate;
