import React, { useState } from "react";
import loginstyles from "./login.styles";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useNavigation } from "react-router-dom";
import urls from "../../global/urlConstant";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const classes = loginstyles;

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  function goToSignUp() {
    navigate("/signup");
  }

  function gotoForgotPassword() {
    navigate("/forgotpassword");
  }

  const handleSubmit = () => {
    const obj = {
      email: email,
      password: password
      }
      axios
      .post(`${urls.baseUrl}/api/auth/login`, obj)
      .then(function (response) {
        console.log(response);
        localStorage.setItem("authToken", response.data.token);
        toast.success("Login successful!", {
          position: "top-right",
        });
        navigate('/dashboard')
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      });
      
  }

  return (
    <Box fontStyle={classes.mainStack}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <img
            style={classes.loginImage}
            src={`images/dashboard/gym-new-img.png`}
            alt={`sample-image`}
            loading="lazy"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={7}
          xl={7}
          style={{ margin: "3vh 0" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={classes.formClass}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              fontSize="40px"
              fontWeight="bolder"
              sx={{ textAlign: "center" }}
            >
              Welcome
            </Typography>
            <Box sx={{ padding: "5px" }}>
              <Typography
                fontSize="12px"
                fontWeight="bolder"
                sx={{ textAlign: "center" }}
              >
                Elevate your fitness journey{" "}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="bolder"
                sx={{ textAlign: "center" }}
              >
                Sign in to a healthier you
              </Typography>
            </Box>
            <Box sx={classes.formLabel}>
              <label style={{ fontSize: "15px" }}>Email Id</label>
              <br />
              <TextField
                placeholder="Your Email"
                size="small"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.35)",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
                onChange={(e: any)=> setEmail(e.target.value)}
              />
            </Box>
            <Box sx={classes.formLabel}>
              <label style={{ margin: "5px 0", fontSize: "15px" }}>
                Password
              </label>
              <br />
              <TextField
                type="password"
                id="outlined-size-small"
                placeholder="Your Password"
                size="small"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.35)",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
                onChange={(e: any)=> setPassword(e.target.value)}
              />
              <br />
              <p
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  color: "white",
                  textAlign: "right",
                  textDecoration: "none",
                }}
                onClick={gotoForgotPassword}
              >
                Forgot Password
              </p>
              {/* <p
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  color: "white",
                  textAlign: "right",
                  textDecoration: "none",
                }}
                onClick={goToSignUp}
              >
                Sign Up!
              </p> */}
              {/* <Checkbox
                {...label}
                sx={{
                  color: "rgba(255, 255, 255, 0.35)",
                  "&.Mui-checked": {
                    color: "rgba(255, 255, 255, 0.35)",
                  },
                }}
              />
              <label style={{ fontSize: "15px" }}>Remember me</label> <br /> */}
              <Button
                variant="contained"
                style={{
                  backgroundColor: " #00FF80",
                  color: "black",
                  borderRadius: "10px",
                }}
                size="small"
                fullWidth
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
};

export default Login;
