import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../components/Sidebar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import gymDetail from "./gymDetail.styles";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import urls from "../../global/urlConstant";

interface ClientData {
  id: number;
  name: string;
  joiningDate: string;
  contact: string;
  email: string;
  expiryDate: string;
  status: string;
  plan: string;
  isEnabled: boolean;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const data: ClientData[] = Array(10).fill({
  name: "Priyanshu",
  joiningDate: "00-00-00",
  contact: "00000000000",
  email: "priya@gmail.com",
  expiryDate: "00-00-00",
  status: "Active",
  plan: "Plan",
  isEnabled: true,
});

const createData = (
  id: number,
  name: string,
  joiningDate: string,
  contact: number,
  email: string,
  expiryDate: string,
  status: string,
  plan: string,
  address: string,
  isEnabled: boolean
) => {
  return {
    id,
    name,
    joiningDate,
    contact,
    email,
    expiryDate,
    status,
    plan,
    address,
    isEnabled,
  };
};

const rows = [
  createData(
    1,
    "Himanshu",
    "12/10/2003",
    9658472536,
    "abc@info.com",
    "13/03/2010",
    "ACTIVE",
    "PLAN",
    "noida sector 16",
    true
  ),
  createData(
    2,
    "Himanshua",
    "12/10/2003",
    9658472536,
    "abc@info.com",
    "13/03/2010",
    "ACTIVE",
    "PLAN",
    "noida sector 16",
    true
  ),
  createData(
    3,
    "Himanshu",
    "12/10/2003",
    9658472536,
    "abc@info.com",
    "13/03/2010",
    "ACTIVE",
    "PLAN",
    "noida sector 16",
    true
  ),
];

const classes = gymDetail;

const GymDetail = () => {
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    isUserLoggedIn();
  }, []);

  const isUserLoggedIn = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      localStorage.removeItem("authToken");
      navigate("/");
    }
  };

  const handleClose = () => setOpen(false);

  const handleDeleteClose = () => setDeleteOpen(false);

  const handleOpen = (row: any) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleDeleteOpen = (row: any) => {
    setSelectedRow(row);
    setDeleteOpen(true);
  };

  const handleConfirmDelete = async (row: any) => {
    console.log("row ", row);
    try {
      if (!row || !row.uniqueId) {
        toast.error("Invalid row data. Cannot delete.", {
          position: "top-right",
        });
        return;
      }

      toast.info("Deleting, please wait...", { position: "top-right" });

      const response = await axios.delete(
        `${urls.baseUrl}/api/gyms/${row.uniqueId}`
      );
      console.log("Delete Response:", response);

      toast.success("Successfully Deleted!", { position: "top-right" });
      setDeleteOpen(false);
      getAllGym();
    } catch (error) {
      console.error("Delete Error:", error);

      const errorMessage = "Delete failed. Please try again.";
      toast.error(errorMessage, { position: "top-right" });
    }
  };

  // const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const [gymData, setGymData] = useState([]);

  console.log(gymData);

  useEffect(() => {
    getAllGym();
  }, []);

  function goToGymDetailUpdate(row: any) {
    navigate("/gymDetailUpdate", { state: row });
  }
  function goToGymDetailOneUpdate(row: any) {
    navigate("/gymDetailOneUpdate", {
      state: { id: row._id, gymName: row.gymName },
    });
  }
  function goToaddGym() {
    navigate("/addgym");
  }

  const onfilterChange = (event: any) => {
    let input = event.target.value;
    if (input) {
      let filteredData = gymData?.filter(
        (o: any) =>
          o.gymName.toLowerCase().includes(input.toLowerCase()) ||
          o.emailAddress.toLowerCase().includes(input.toLowerCase()) ||
          o.contact.toLowerCase().includes(input.toLowerCase())
      );
      setGymData(filteredData);
    } else {
      getAllGym();
    }

    console.log("input", input);
  };

  const getFirst15Characters = (str: String) => {
    const subName = str?.length > 16 ? str?.slice(0, 16) : str;
    if (str.length > 16) {
      return subName + "...";
    } else {
      return str;
    }
  };

  // function formatDateManually(isoDateString: string | number | Date) {
  //   const date = new Date(isoDateString);
  //   const day = date.getDate();
  //   const month = date.toLocaleString("default", { month: "long" });
  //   const year = date.getFullYear();

  //   return `${day} ${month} ${year}`;
  // }

  function formatDateManually(isoDateString: string | number | Date) {
    console.log("isoDateString ", isoDateString);
    if (isoDateString) {
      // Convert the input string "20251030" into a Date object
      const isoDateStringFormatted = isoDateString.toString();
      const year = isoDateStringFormatted.slice(0, 4); // Extract year
      const month = isoDateStringFormatted.slice(4, 6); // Extract month
      const day = isoDateStringFormatted.slice(6, 8); // Extract day

      // Return the formatted string as "yyyy/mm/dd"
      return `${year}/${month}/${day}`;
    } else {
      return null;
    }
  }

  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value);
  // };

  const [selectedDays, setSelectedDays] = useState<number | undefined>();

  const handleDropdownChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const days = Number(event.target.value); // Convert selected value to a number
    setSelectedDays(days);

    // Hit the API with the selected value
    try {
      const response = await axios.get(
        `${urls.baseUrl}/api/gyms/expiring/days?days=${days}`
        // {
        //   responseType: "blob", // Ensure the response is treated as a binary file
        // }
      );
      if (response.data.data) {
        setGymData(response.data.data);
        console.log("response.data.data", response.data.data); // Handle the response data
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error: any) {
      console.error("Error while fetching:", error);
      toast.error(error.response.data.message, { position: "top-right" });
    }
  };

  // for selection in the table

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  // Check if a specific row is selected
  const isSelected = (_id: string) => selectedRows.includes(_id);

  // Toggle selection for a specific row
  const toggleRowSelection = (_id: any) => {
    if (isSelected(_id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== _id)); // Deselect
    } else {
      setSelectedRows([...selectedRows, _id]); // Select
    }
  };

  // Toggle selection for all rows
  const toggleSelectAll = () => {
    if (selectedRows.length === gymData.length) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(gymData.map((row: any) => row._id)); // Select all
    }
  };

  const getAllGym = async () => {
    await axios
      .get(`${urls.cloudFunctionBaseUrl}/gyms`)
      .then(function (response) {
        console.log(response);
        console.log('response.data.data', response.data)
        console.log('response.data.data.gyms', response.data.gyms)
        setGymData(response.data.gyms);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Technical issue while getting gym details!", {
          position: "top-right",
        });
      });
  };

  // pagination code starts here

  const [page, setPage] = useState(1); // Current page (1-based index)
  const rowsPerPage = 10; // Rows per page (fixed to 10)

  const totalPages = Math.ceil(gymData.length / rowsPerPage); // Total pages

  // Get current rows to display based on the current page
  const currentRows = gymData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage); // Update the current page
    }
  };
  // pagination code ends here

  const handleExport = async () => {
    try {
      // Make API request to get the file

      const response = await axios.post(
        `${urls.baseUrl}/api/gyms/export/csv`,
        { gymIds: selectedRows },
        {
          responseType: "blob", // Ensure the response is treated as a binary file
        }
      );

      // Create a blob and download the file
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "exported_file.csv"; // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  // handling enable new date

  const [currDate, setCurrDate] = useState<number>();

  console.log("current date is :", currDate);

  // handling date format

  const formatDateToYYYYMMDD = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
  };

  const handleEnableDateChange = (e: any) => {
    let tempDate = formatDateToYYYYMMDD(e.target.value);

    setCurrDate(tempDate);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        paddingLeft: "10px",
        paddingTop: "16px",
      }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid
            container
            style={{ marginLeft: "10px", marginBottom: "10px" }}
            rowSpacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                marginTop: "15px",
                color: "#343434",
                padding: "0px 3px",
              }}
            >
              {" "}
              List of Gym
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginRight: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ marginLeft: "5px", padding: "10px" }}>
              <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: "#C98630" }}
                onClick={handleExport}
              >
                Export
              </Button>
            </Typography>

            <div>
              {" "}
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 140,
                  background: "rgb(201, 134, 48)",
                  border: "none",
                  color: "white",
                }}
                size="small"
              >
                <label htmlFor="daysDropdown">Select Expiring Days:</label>
                <select
                  id="daysDropdown"
                  value={selectedDays ?? ""}
                  onChange={handleDropdownChange}
                >
                  <option value="">Select</option>
                  <option value="2">2 Days</option>
                  <option value="4">4 Days</option>
                  <option value="15">15 Days</option>
                </select>
              </FormControl>
            </div>

            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{ padding: "10px", textAlign: "right" }}
            ></Grid>
            {/* </Grid> */}
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Filter"
              size="small"
              sx={{ maxWidth: 160, margin: " 5px 0 5px 16px" }}
              onChange={onfilterChange}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />{" "}
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#C98630",
                maxWidth: 160,
                margin: " 10px 0 10px 16px",
              }}
              onClick={goToaddGym}
            >
              Add Gym
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        ></Box>
        <Box>
          <TableContainer sx={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedRows.length === gymData.length}
                      onChange={toggleSelectAll}
                    />
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", minWidth: 220 }}>
                    Name Of Gym
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", minWidth: 140 }}>
                    Contact no
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", minWidth: 120 }}>
                    Email
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", minWidth: 120 }}>
                    Address
                  </TableCell>
                  <TableCell
                    style={{ textDecoration: "underline" }}
                  ></TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    View/Edit{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell>
                  {/* <TableCell
                    style={{ textDecoration: "underline" }}
                  ></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {gymData.map((row: any, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: "white",
                      height: "30px", // Adjust height
                      "& .MuiTableCell-root": {
                        // Adjust padding for all TableCells in this row
                      },
                      "&:hover": {
                        backgroundColor: "#FFDEDE",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected(row._id)}
                        onChange={() => toggleRowSelection(row._id)}
                      />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        goToGymDetailOneUpdate(row);
                      }}
                    >
                      <Tooltip title={`${row.gymName}`} placement="bottom">
                        <Typography>
                          {" "}
                          {getFirst15Characters(row.gymName)}
                        </Typography>
                      </Tooltip>
                    </TableCell>

                    <TableCell>{row.contact}</TableCell>
                    <TableCell>{row.emailAddress}</TableCell>

                    {/* <TableCell>{row.status}</TableCell> */}
                    {/* <TableCell>{row.plan}</TableCell> */}
                    <TableCell sx={{ minWidth: 160 }}>
                      {" "}
                      <Tooltip title={`${row.address}`} placement="bottom">
                        <Button style={{ color: "black" }}>
                          {getFirst15Characters(row.address)}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Button
                        onClick={() => goToGymDetailUpdate(row)}
                        sx={{ zIndex: "1" }}
                      >
                        <EditIcon style={{ color: "rgb(201, 134, 48)" }} />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={{ zIndex: "1" }}
                        onClick={() => handleDeleteOpen(row)}
                      >
                        {" "}
                        <DeleteOutlineIcon
                          style={{ color: "rgb(201, 134, 48)" }}
                        />
                      </Button>
                      {/* delete functionality starts here */}
                      <Modal
                        open={deleteOpen}
                        onClose={handleDeleteClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ backgroundColor: "black !important" }}
                      >
                        <Box sx={style}>
                          <Box
                            component="main"
                            sx={{
                              padding: "0px !important",
                              flexGrow: 1,
                              p: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Stack>
                              <Grid
                                container
                                style={{
                                  backgroundColor: "#C98630",
                                  borderRadius: "10px",
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    style={{
                                      backgroundColor: " #FFDCAE",
                                      textAlign: "center",
                                      padding: "10px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    Dashboard Name
                                  </Typography>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      padding: "5px",
                                      margin: "5px",
                                    }}
                                  >
                                    {/* <ErrorOutlineRoundedIcon style={{color:'white',margin:'0 5px'}}/> */}
                                    <Typography style={{ color: "white" }}>
                                      Are you sure to delete this?
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      padding: "5px",
                                      margin: "5px",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: "#FFDCAE",
                                        padding: "7px 22px",
                                        borderRadius: "10px",
                                        margin: "2px 24px",
                                        color: "black",
                                      }}
                                      onClick={() => handleConfirmDelete(row)}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      style={{
                                        backgroundColor: "#FFDCAE",
                                        padding: "7px 22px",
                                        borderRadius: "10px",
                                        margin: "2px 24px",
                                        color: "black",
                                      }}
                                      onClick={handleDeleteClose}
                                    >
                                      No
                                    </Button>
                                    {/* <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>Yes</Typography>
                       <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>No</Typography> */}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Box>
                        </Box>
                      </Modal>
                      {/* delete functionality ends here */}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: row.isExpired ? " #00FF80" : "RED",
                          color: row.isExpired ? "black" : "white",
                          borderRadius: "5px",
                        }}
                        size="small"
                        onClick={() => handleOpen(row)}
                        fullWidth
                      >
                        {row.isExpired ? "Enable" : "Disable"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Stack
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Pagination
            count={40}
            variant="outlined"
            shape="rounded"
            sx={{ color: "red" }}
          /> */}

          {/* pagination code  starts here*/}

          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              // style={{ marginRight: "8px" }}
              sx={{
                border: "1px solid rgb(201, 134, 48) !important",
                color: "black !important",
                marginRight: "8px",
              }}
            >
              &lt; Previous
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                sx={{
                  border: "1px solid rgb(201, 134, 48) !important",
                  color: "black !important",
                  backgroundColor: "white !important",
                  "&:hover": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:visited": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:focus": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:active": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                }}
                key={index + 1}
                variant={index + 1 === page ? "contained" : "outlined"}
                size="small"
                onClick={() => handlePageChange(index + 1)}
                style={{ marginRight: "4px" }}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              variant="outlined"
              size="small"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
              sx={{
                border: "1px solid rgb(201, 134, 48) !important",
                color: "black !important",
                marginRight: "8px",
              }}
            >
              Next &gt;
            </Button>
          </Box>

          {/* pagination code  ends here*/}
        </Stack>
      </Box>
      {/* enable modal code starts below */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ backgroundColor: "black !important" }}
      >
        <Box sx={style}>
          <Box
            component="main"
            sx={{
              padding: "0px !important",
              flexGrow: 1,
              p: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack>
              <Grid
                container
                style={{
                  backgroundColor: "#C98630",
                  borderRadius: "10px",
                }}
              >
                <Grid item>
                  <Typography
                    style={{
                      backgroundColor: " #FFDCAE",
                      textAlign: "center",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    Dashboard Name
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px",
                      margin: "5px",
                    }}
                  >
                    {/* <ErrorOutlineRoundedIcon style={{color:'white',margin:'0 5px'}}/> */}
                    <Typography style={{ color: "white" }}>
                      Gym Name: {selectedRow?.gymName}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      Date: {selectedRow?.expiresAt}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      Select Date:{" "}
                      <input
                        type="date"
                        name=""
                        id=""
                        onChange={handleEnableDateChange}
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "5px",
                      margin: "5px",
                      justifyContent: "space-around",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#FFDCAE",
                        padding: "7px 22px",
                        borderRadius: "10px",
                        margin: "2px 24px",
                        color: "black",
                      }}
                      // onClick={handleConfirmDelete}
                      // disabled={currDate}

                      onClick={async () => {
                        await axios.put(
                          `${urls.baseUrl}/api/gyms/${selectedRow.uniqueId}`,
                          {
                            expiresAt: currDate,
                            isExpired: selectedRow.isExpired ? false : true,
                          }
                        );
                        handleClose();
                        getAllGym();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#FFDCAE",
                        padding: "7px 22px",
                        borderRadius: "10px",
                        margin: "2px 24px",
                        color: "black",
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    {/* <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>Yes</Typography>
                       <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>No</Typography> */}
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Box>
      </Modal>
      {/* enable modal code ends here */}
      <ToastContainer />
    </Box>
  );
};

export default GymDetail;
