import styles from "./ClientQuery.module.css";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import MiniDrawer from "../../components/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";

import clientQuerystyles from "./clientQuery.styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteIcon from "@mui/icons-material/Delete";
import { Padding } from "@mui/icons-material";
import axios from "axios";
import urls from "../../global/urlConstant";

const classes = clientQuerystyles;

interface EditableGymData {
  clientId: string;
  clientName: string;
  comments: string;
  createdAt: string;
  email: string;
  queryText: string;
  status: string;
  _id: string;
}

const ClientQueryUpdate: FC = () => {
  const [status, setStatus] = useState();
  const location = useLocation();
  const receivedData = location.state;

  console.log("receivedData", receivedData);

  const navigate = useNavigate();

  function gotToQuery() {
    navigate("/clientQuery");
  }

  //

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    Padding: "10px",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [editableData, setEditableData] = useState(receivedData); // Create a local state from props

  const handleInputChange =
    (field: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setEditableData((prevData: EditableGymData) => ({
        ...prevData,
        [field]: event.target.value,
      }));
    };

  // for email number change
  const handleEmailChange = (event: any) => {
    setEditableData({ ...editableData, email: event.target.value }); // Update email
  };
  // for email number change
  const handleMessageChange = (event: any) => {
    setEditableData({ ...editableData, message: event.target.value }); // Update number
  };
  // for email number change
  const handleContactChange = (event: any) => {
    setEditableData({ ...editableData, contactNo: event.target.value }); // Update number
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log("editableData", editableData);

    const obj = { status: editableData.status };

    console.log("obj: ", obj);
    try {
      await axios.patch(
        `${urls.baseUrl}/api/queries/${editableData._id}/status`,
        obj
      );
      console.log("Update successful");
      navigate("/clientQuery");
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1, display: "flex", justifyContent: "center" }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography sx={classes.mainHead}>
            Client Query
          </Typography> */}
          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>

        <Stack>
          <Typography
            variant="h5"
            style={{
              fontWeight: "700",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "15px 10px",
            }}
          >
            Client Query - Edit / View
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center", margin: "10px", padding: "10px" }}
            >
              {" "}
              <Button onClick={handleOpen}>
                <CloudUploadIcon
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "100px",
                    fontSize: "40px",
                  }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => console.log(event.target.files)}
                        multiple
                      />
                    </Button>

                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<FindReplaceIcon />}
                    >
                      Replace Image
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Image
                    </Button>
                  </Box>
                </Modal>
              </Button>
              <Typography>Upload Image</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Name of gym</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter Gym"
                  value={editableData.clientName}
                  disabled
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Email</label>
                <input
                  type="email"
                  className={styles.inputField}
                  placeholder="Enter Email"
                  value={editableData.email}
                  disabled
                  // onChange={handleEmailChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Message</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter Message"
                  value={editableData.queryText}
                  disabled
                  // onChange={handleMessageChange}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Status</label>
                <select
                  value={editableData.status}
                  className={styles.selectField}
                  onChange={handleInputChange("status")}
                  // onChange={handleInputChange("plan")}
                >
                  <option value="">Select</option>
                  <option value="open">open</option>
                  <option value="closed">closed</option>
                </select>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                marginTop: "20px",
                alignItems: "center",
                gap: "200px",
                // border:"2px solid black"
              }}
            >
              {" "}
              <div>
                <button className={styles.cancelButton} onClick={gotToQuery}>
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Stack>

        {/* <div className={styles.formContainer}>
          <h2 className={styles.heading}>Client Query - Edit / View</h2>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Name of gym</label>
              <select className={styles.selectField}>
                <option value="">Select</option>
                <option value="gym1">Gym 1</option>
                <option value="gym2">Gym 2</option>
              </select>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Contact No.</label>
              <input type="number" className={styles.inputField} placeholder='Enter Number' />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Email</label>
              <input type="email" className={styles.inputField} placeholder='Enter Email' />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Message</label>
              <input type="text" className={styles.inputField} placeholder="Enter Message" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.cancelButton} onClick={gotToQuery}>Cancel</button>
            <button className={styles.saveButton}>Save</button>
          </div>
        </div> */}
      </Box>
    </Box>
  );
};

export default ClientQueryUpdate;
