import React, { FC, useState } from "react";
import styles from "./GymDetailUpdate.module.css";
import MiniDrawer from "../../components/Sidebar";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import gymDetail from "./gymDetail.styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from "react-toastify";
import urls from "../../global/urlConstant";

const classes = gymDetail;

const AddMember: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const gymId = location.state;

  function goToGymDetail() {
    navigate("/gymDetailOneUpdate", { state: { id: gymId } });
  }

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    Padding: "10px",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [firstName, setfirstName] = useState();
  const [lastName, setlastName] = useState();
  const [fullName, setfullName] = useState();
  // const [email, setemail] = useState();
  const [mobileNumber, setmobileNumber] = useState();
  const [aadharCardNo, setaadharCardNo] = useState();
  const [dateOfBirth, setDateOfBirth] = useState<number | undefined>(undefined);
  const [memberId, setmemberId] = useState();

  const [currentAddress, setcurrentAddress] = useState();
  const [birthMonthDate, setbirthMonthDate] = useState<number | undefined>(
    undefined
  );
  const [gender, setgender] = useState();
  const [actualPackagePrice, setactualPackagePrice] = useState();
  const [currentPackageId, setcurrentPackageId] = useState();
  const [mypackage, setmypackage] = useState();
  const [joiningPackageDate, setjoiningPackageDate] = useState<
    number | undefined
  >(undefined);
  const [amountPaid, setamountPaid] = useState();
  // const [paymentDate, setpaymentDate] = useState<number | undefined>(undefined);
  const [status, setstatus] = useState(false);

  const handleSubmit = async (event: any) => {
    const obj = {
      aadharCardNo: aadharCardNo,
      actualPackagePrice: actualPackagePrice,
      amountPaid: amountPaid,
      birthMonthDate: birthMonthDate,
      currentAddress: currentAddress,
      currentPackageId: currentPackageId,
      dateOfBirth: dateOfBirth,
      email: email,
      firstName: firstName,
      fullName: fullName,
      gender: gender,
      joiningPackageDate: joiningPackageDate,
      lastName: lastName,
      memberId: memberId,
      mobileNumber: mobileNumber,
      package: mypackage,
      status: status,
    };

    console.log("obj", obj);
    await axios
      .post(`${urls.baseUrl}/api/gym-owner/${gymId}/Members`, obj)
      .then(function (response) {
        console.log(response);
        toast.success("Gym Added!", {
          position: "top-right",
        });
        navigate(-1);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Gym Not Added!", {
          position: "top-right",
        });
      });
    // };
  };

  const handlefirstNameChange = (event: any) => {
    console.log(event.target.value);
    setfirstName(event.target.value);
  };
  const handlelastNameChange = (event: any) => {
    console.log(event.target.value);
    setlastName(event.target.value);
  };
  const handlefullNameChange = (event: any) => {
    console.log(event.target.value);
    setfullName(event.target.value);
  };
  // const handleemailChange = (event: any) => {
  //   console.log(event.target.value);
  //   setemail(event.target.value);
  // };


  // custom code to validation for contact number

  const [valueContact, setValueContact] = useState('');

  const handlesetmobileNumberChange = (event: any) => {
    let newValue = event.target.value

    if (newValue.length <= 10) {
      setValueContact(newValue);
    }

    // setmobileNumber(event.target.value);
  };
  const handlepackageChange = (event: any) => {
    console.log(event.target.value);
    setmypackage(event.target.value);
  };
  const handlememberIdChange = (event: any) => {
    console.log(event.target.value);
    setmemberId(event.target.value);
  };
  const handlecurrentAddressChange = (event: any) => {
    console.log(event.target.value);
    setcurrentAddress(event.target.value);
  };
  const handlebirthMonthDateChange = (event: any) => {
    console.log(event.target.value);
    const date = new Date(event.target.value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
    setbirthMonthDate(formattedDate);
  };

  const handleactualPackagrPriceChange = (event: any) => {
    console.log(event.target.value);
    setactualPackagePrice(event.target.value);
  };
  const handlecurrentPackageChange = (event: any) => {
    console.log(event.target.value);
    setcurrentPackageId(event.target.value);
  };
  const handlegenderChange = (event: any) => {
    console.log(event.target.value);
    setgender(event.target.value);
  };
  const handleaadharCardNoChange = (event: any) => {
    console.log(event.target.value);
    setaadharCardNo(event.target.value);
  };
  const handleamountPaidChange = (event: any) => {
    console.log(event.target.value);
    setamountPaid(event.target.value);
  };
  const handledateOfBirthChange = (event: any) => {
    console.log(event.target.value);
    const date = new Date(event.target.value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
    setDateOfBirth(formattedDate);
  };

  const formatDateToYYYYMMDD = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
  };
  // const handlejoiningPackageDateChange = (event: any) => {
  //   console.log(event.target.value);
  //   const date = new Date(event.target.value);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const formattedDate = Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
  //   setjoiningPackageDate(formattedDate);
  // };
  const handlejoiningPackageDateChange = (event: any) => {
    // console.log("eexpiring date", event.target.value);
    // Convert to number in YYYYMMDD format
    const formattedDate = formatDateToYYYYMMDD(event.target.value);
    console.log("formatted date is:", formattedDate);

    setjoiningPackageDate(formattedDate);
  };

  const handlestatusChange = (event: any) => {
    console.log(event.target.value);
    setstatus(event.target.value);
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setEmail(value);

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        padding: "10px",
      }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography sx={classes.mainHead}>
                        Client Query
                    </Typography> */}
          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>
        <Stack>
          <Typography
            variant="h5"
            style={{
              fontWeight: "700",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "15px 10px",
            }}
          >
            Add Gym Member
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center", margin: "10px", padding: "10px" }}
            >
              {" "}
              <Button onClick={handleOpen}>
                <CloudUploadIcon
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "100px",
                    fontSize: "40px",
                  }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => console.log(event.target.files)}
                        multiple
                      />
                    </Button>

                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<FindReplaceIcon />}
                    >
                      Replace Image
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Image
                    </Button>
                  </Box>
                </Modal>
              </Button>
              <Typography>Upload Image</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>First Name</label>
                <input
                  type="text"
                  id="firstName"
                  className={styles.inputField}
                  placeholder="Enter first name"
                  onChange={handlefirstNameChange}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  className={styles.inputField}
                  placeholder="Enter last name"
                  onChange={handlelastNameChange}
                />
              </div>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  className={styles.inputField}
                  placeholder="Enter full name"
                  onChange={handlefullNameChange}
                />
              </div>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              {/* <div className={styles.formGroup}>
                <label className={styles.label}> Email</label>
                <input
                  type="text"
                  id="email"
                  className={styles.inputField}
                  placeholder="Enter email"
                  onChange={handleemailChange}
                />
              </div> */}
              <div className={styles.formGroup}>
                <label className={styles.label}>Email</label>
                <input
                  type="email"
                  className={styles.inputField}
                  placeholder="Enter Email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && (
                  <span className={styles.error}>{emailError}</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Mobile No</label>
                <input
                  type="number"
                  id="mobileNumber"
                  className={styles.inputField}
                  placeholder="Enter mobile no"
                  value={valueContact}
                  onChange={handlesetmobileNumberChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Member Id</label>
                <input
                  type="number"
                  id="memberId"
                  className={styles.inputField}
                  placeholder="Enter mobile no"
                  onChange={handlememberIdChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Package</label>
                <select
                  className={styles.selectField}
                  onChange={handlepackageChange}
                >
                  <option value="">Select</option>
                  <option value="SILVER">Silver</option>
                  <option value="GOLD">Gold</option>
                  <option value="DIAMOND">Diamond</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Status</label>
                <select
                  className={styles.selectField}
                  onChange={handlestatusChange}
                >
                  <option value="">Select</option>
                  <option value="true">Enable</option>
                  <option value="false">Disable</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Joining Package Date</label>
                <input
                  type="date"
                  id="joiningPackageDate"
                  className={styles.inputField}
                  placeholder="Enter joining package date"
                  onChange={(e) => handlejoiningPackageDateChange(e)}
                // onChange={(e) => handleExpiryDateChange(e)}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Gender</label>
                {/* <input
                  type="text"
                  id="gender"
                  className={styles.inputField}
                  placeholder="Enter gender"
                  onChange={handlegenderChange}
                /> */}
                <select
                  id="gender"
                  className={styles.selectField}
                  onChange={handlegenderChange}
                >
                  <option value="">Select</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Date Of Birth</label>
                <input
                  type="date"
                  id="dateOfBirth"
                  className={styles.inputField}
                  placeholder="Enter date of birth"
                  onChange={handledateOfBirthChange}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Current Package Id</label>
                <input
                  type="number"
                  id="currentPackageId"
                  className={styles.inputField}
                  placeholder="Enter current package Id"
                  onChange={handlecurrentPackageChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Current Address</label>
                <input
                  type="text"
                  id="currentAddress"
                  className={styles.inputField}
                  placeholder="Enter current address"
                  onChange={handlecurrentAddressChange}
                />
              </div>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Birth Month Date</label>
                <input
                  type="date"
                  id="birthMonthDate"
                  className={styles.inputField}
                  placeholder="Enter birth month date"
                  onChange={handlebirthMonthDateChange}
                />
              </div>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Amount Paid</label>
                <input
                  type="number"
                  id="amountPaid"
                  className={styles.inputField}
                  placeholder="Enter amount Paid"
                  onChange={handleamountPaidChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Actual Package Price</label>
                <input
                  type="number"
                  id="actualPackagePrice"
                  className={styles.inputField}
                  placeholder="Enter actual package price"
                  onChange={handleactualPackagrPriceChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Adhar Card No.</label>
                <input
                  type="number"
                  id="adharNo"
                  className={styles.inputField}
                  placeholder="Enter aadhar"
                  onChange={handleaadharCardNoChange}
                />
              </div>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Date Of Birth</label>
                <input
                  type="date"
                  id="dateOfBirth"
                  className={styles.inputField}
                  placeholder="Enter date of birth"
                  onChange={handledateOfBirthChange}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Member Id</label>
                <input
                  type="number"
                  id="memberId"
                  className={styles.inputField}
                  placeholder="Enter member id"
                  onChange={handlememberIdChange}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Joining Package Date</label>
                <input
                  type="date"
                  id="payment"
                  className={styles.inputField}
                  placeholder="Enter payment date"
                  onChange={handlejoiningPackageDateChange}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}> Remark</label>
                <input
                  type="text"
                  id="remark"
                  className={styles.inputField}
                  placeholder="Enter remark"
                  onChange={handleremarkChange}
                />
              </div>
            </Grid> */}
            {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Status</label>
                <select
                  className={styles.selectField}
                  onChange={handlestatusChange}
                >
                  <option value="">Select</option>
                  <option value="true">Enable</option>
                  <option value="false">Disable</option>
                </select>
              </div>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                marginTop: "20px",
                alignItems: "center",
                gap: "200px",
                // border:"2px solid black"
              }}
            >
              {" "}
              <div>
                <button
                  className={styles.cancelButton}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

export default AddMember;
