import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import axios from "axios";
import urls from "../../global/urlConstant";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../loader/Loader";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(""); // Clear error on input change
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    const obj = {
      email: email,
    };
    axios
      .post(`${urls.baseUrl}/api/auth/forgot-password`, obj)
      .then(function (response) {
        console.log(response);
        setLoading(false);
        toast.success("Reset Password Link Sent!", {
          position: "top-right",
        });
        setEmail("");
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        toast.error("Facing technical issue while resetting password!", {
          position: "top-right",
        });
      });
    alert("If this email exists, a reset link will be sent!");
  };

  return loading ? (
    <Loader />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
      bgcolor="#f9f9f9"
    >
      <Typography variant="h4" gutterBottom>
        Forgot Password
      </Typography>
      <Typography variant="body1" paragraph>
        Enter your email address, and we'll send you a link to reset your
        password.
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: 400 }}>
        <TextField
          label="Email Address"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={handleInputChange}
          error={!!emailError}
          helperText={emailError}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Send Reset Link
        </Button>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default ForgotPassword;
