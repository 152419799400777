import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Modal,
  Pagination,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../components/Sidebar";
import clientstyles from "./clientfeedback.styles";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { toast } from "react-toastify";
import urls from "../../global/urlConstant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const createData = (
  id: number,
  uniqueId: number,
  userId: number,
  name: string,
  contactNo: number,
  email: any,
  description: string
) => {
  return { id, name, uniqueId, contactNo, userId, email, description };
};

const rows = [
  createData(1, 6543298765, 123, "Himanshu", 123, "joe@gmail.com", "I am Joe"),
];

const classes = clientstyles;

const Clientfeedback = () => {
  const navigate = useNavigate();

  const [feedbackData, setFeedbackData] = useState([]);

  console.log(feedbackData);

  useEffect(() => {
    isUserLoggedIn();
    getallFeedback();
  }, []);

  const isUserLoggedIn = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      localStorage.removeItem("authToken");
      navigate("/");
    }
  };

  const goToClientFeedbackUpdate = (row: any) => {
    navigate("/clientFeedbackUpdate", { state: row });
  };

  function goToFeedback() {
    navigate("/AddFeedback");
  }

  const onfilterChange = (event: any) => {
    let input = event.target.value;
    if (input) {
      let filteredData = feedbackData?.filter(
        (o: any) =>
          o.name.toLowerCase().includes(input.toLowerCase()) ||
          o.email.toLowerCase().includes(input.toLowerCase())
      );
      setFeedbackData(filteredData);
    } else {
      getallFeedback();
    }

    console.log("input", input);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getFirst15Characters = (str: String) => {
    const subName = str?.length > 15 ? str?.slice(0, 15) : str;
    return subName + "...";
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  const [selectAll, setSelectAll] = useState(false);

  const handleConfirmDelete = () => {
    // console.log("Current Row is : ", selectedRow);
    axios
      .delete(`${urls.baseUrl}/api/auth/signup`)
      .then(function (response) {
        console.log(response);
        toast.success("Successfully Deleted!", {
          position: "top-right",
        });
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Delete failed. Please try again.", {
          position: "top-right",
        });
      });
  };

  // for selection in the table

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  // Check if a specific row is selected
  const isSelected = (_id: string) => selectedRows.includes(_id);

  // Toggle selection for a specific row
  const toggleRowSelection = (_id: any) => {
    if (isSelected(_id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== _id)); // Deselect
    } else {
      setSelectedRows([...selectedRows, _id]); // Select
    }
  };

  // Toggle selection for all rows
  const toggleSelectAll = () => {
    if (selectedRows.length === feedbackData.length) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(feedbackData.map((row: any) => row._id)); // Select all
    }
  };

  const getallFeedback = async () => {
    await axios
      .get(`${urls.baseUrl}/api/feedbacks`)
      .then(function (response) {
        console.log(response);
        setFeedbackData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Technical issue while getting gym details!", {
          position: "top-right",
        });
      });
  };

  // pagination code starts here

  const [page, setPage] = useState(1); // Current page (1-based index)
  const rowsPerPage = 10; // Rows per page (fixed to 10)

  const totalPages = Math.ceil(feedbackData.length / rowsPerPage); // Total pages

  // Get current rows to display based on the current page
  const currentRows = feedbackData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage); // Update the current page
    }
  };

  const handleExport = async () => {
    try {
      // Make API request to get the file
      const response = await axios.get(
        `${urls.baseUrl}/api/feedbacks/export/csv`
        // {
        //   responseType: "blob", // Ensure the response is treated as a binary file
        // }
      );

      // Create a blob and download the file
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "exported_file.csv"; // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  // pagination code ends here

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        paddingLeft: "10px",
        paddingTop: "16px",
      }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={classes.mainHead}>Client Feedback</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginRight: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ marginLeft: "5px", padding: "10px" }}>
              <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: "#C98630" }}
                onClick={handleExport}
              >
                Export
              </Button>
            </Typography>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{ padding: "10px", textAlign: "right" }}
            ></Grid>
            {/* </Grid> */}
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Filter"
              size="small"
              sx={{ maxWidth: 160, margin: " 5px 0 5px 16px" }}
              onChange={onfilterChange}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />{" "}
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        ></Box>
        <TableContainer sx={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={selectedRows.length === feedbackData.length}
                    onChange={toggleSelectAll}
                  />
                </TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",

                    minWidth: 140,
                  }}
                >
                  Name of Gym
                </TableCell>
                {/* <TableCell></TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",

                    minWidth: 140,
                  }}
                >
                  Member Id
                </TableCell>
                {/* <TableCell></TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",

                    maxWidth: 120,
                  }}
                >
                  E-mail
                </TableCell>
                {/* <TableCell></TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",

                    maxWidth: 120,
                  }}
                >
                  Message
                </TableCell>
                {/* <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell> */}
                {/* <TableCell style={{ fontWeight: "bold" }}>View/Edit </TableCell> */}
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {feedbackData.map((row: any, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: "white",
                    height: "36px",
                    "& .MuiTableCell-root": {},
                    "&:hover": {
                      backgroundColor: "#FFDEDE",
                      cursor: "pointer",
                    },
                  }}
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected(row._id)}
                      onChange={() => toggleRowSelection(row._id)}
                    />
                  </TableCell> */}
                  <TableCell>
                    <Tooltip title={`${row.name}`} placement="bottom">
                      <Typography> {getFirst15Characters(row.name)}</Typography>
                    </Tooltip>
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell>{row.memberId}</TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell>{row.email}</TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell style={{ minWidth: 220 }}>
                    {row.description}
                  </TableCell>
                  {/* <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell> */}
                  {/* <TableCell>
                    <Button
                      onClick={() => goToClientFeedbackUpdate(row)}
                      sx={{ zIndex: "1" }}
                    >
                      <EditIcon style={{ color: "rgb(201, 134, 48)" }} />
                    </Button>
                  </TableCell> */}
                  {/* <TableCell></TableCell> */}
                  <TableCell>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Box
                          component="main"
                          sx={{
                            padding: "0px !important",
                            flexGrow: 1,
                            p: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Stack>
                            <Grid
                              container
                              style={{
                                backgroundColor: "#C98630",
                                borderRadius: "10px",
                              }}
                            >
                              <Grid item>
                                <Typography
                                  style={{
                                    backgroundColor: " #FFDCAE",
                                    textAlign: "center",
                                    padding: "10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Dashboard Name
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    margin: "5px",
                                  }}
                                >
                                  <Typography style={{ color: "white" }}>
                                    Are you sure to delete this?
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    margin: "5px",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "#FFDCAE",
                                      padding: "7px 22px",
                                      borderRadius: "10px",
                                      margin: "2px 24px",
                                      color: "black",
                                    }}
                                    onClick={handleConfirmDelete}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: "#FFDCAE",
                                      padding: "7px 22px",
                                      borderRadius: "10px",
                                      margin: "2px 24px",
                                      color: "black",
                                    }}
                                    onClick={handleClose}
                                  >
                                    No
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Box>
                      </Box>
                    </Modal>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Pagination count={40} variant="outlined" shape="rounded" /> */}
          {/* pagination code  starts here*/}

          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              // style={{ marginRight: "8px" }}
              sx={{
                border: "1px solid rgb(201, 134, 48) !important",
                color: "black !important",
                marginRight: "8px",
              }}
            >
              &lt; Previous
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                sx={{
                  border: "1px solid rgb(201, 134, 48) !important",
                  color: "black !important",
                  backgroundColor: "white !important",
                  "&:hover": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:visited": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:focus": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:active": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                }}
                key={index + 1}
                variant={index + 1 === page ? "contained" : "outlined"}
                size="small"
                onClick={() => handlePageChange(index + 1)}
                style={{ marginRight: "4px" }}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              variant="outlined"
              size="small"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
              sx={{
                border: "1px solid rgb(201, 134, 48) !important",
                color: "black !important",
                marginRight: "8px",
              }}
            >
              Next &gt;
            </Button>
          </Box>

          {/* pagination code  ends here*/}
        </Stack>
      </Box>
    </Box>
  );
};

export default Clientfeedback;
