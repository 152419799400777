import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../components/Sidebar";
import loginstyles from "../login/login.styles";

import { Delete, Edit, Rowing, Search } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import gymDetail from "./gymDetail.styles";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import urls from "../../global/urlConstant";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const createData = (
  aadharCardNo: number,
  actualPackagePrice: number,
  amountPaid: number,
  birthMonthDate: number,
  currentAddress: string,
  currentPackageId: any,
  dateOfBirth: number,
  email: string,
  firstName: string,
  fullName: string,
  gender: string,
  joiningPackageDate: number,
  lastName: string,
  memberId: number,
  mobileNumber: number,
  mypackage: string,
  status: boolean
) => {
  return {
    status,
    mypackage,
    mobileNumber,
    memberId,
    lastName,
    joiningPackageDate,
    gender,
    fullName,
    firstName,
    email,
    dateOfBirth,
    currentPackageId,
    currentAddress,
    birthMonthDate,
    amountPaid,
    actualPackagePrice,
    aadharCardNo,
  };
};

// const rows = [
//   createData(1, "Priyansh kumar", "45454", "PLAN", 567, "enabled"),
//   createData(2, "Priyansh kumar", "45454", "PLAN", 567, "disabled"),
//   createData(3, "Priyansh kumar", "45454", "PLAN", 567, "enabled"),
//   createData(4, "Priyansh kumar", "45454", "PLAN", 567, "enabled"),
//   createData(5, "Priyansh kumar", "45454", "PLAN", 567, "enabled"),
// ];

const classes = gymDetail;

const GymDetailOneUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location is", location);

  const gymId = location.state.id;
  const gymName = location.state.gymName;
  console.log("gymId ", gymId);
  const [newid, setnewid] = useState([]);

  useEffect(() => {
    getAllGym();
  }, []);

  function goToGymDetailOneUpdateOne(row: any) {
    navigate("/gymDetailOneUpdateEdit", { state: { row: row, gymId: gymId } });
  }

  function gotoAddMember() {
    navigate("/AddMember", { state: gymId });
  }

  function goToClientfeedback() {
    navigate("/dashboard");
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const getFirst15Characters = (str: String) => {
  //   const subName = str?.length > 16 ? str?.slice(0, 16) : str;
  //   if (str.length > 16) {
  //     return subName + "...";
  //   } else {
  //     return str;
  //   }
  // };

  // for selection in the table

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  // const handleSelectAll = (event: any) => {
  //   const isChecked = event.target.checked;
  //   setSelectAll(isChecked);

  //   // Update all rows to be selected/deselected
  //   if (isChecked) {
  //     setSelectedRows(rows.map((row) => row.rowId)); // Assuming rows have a unique 'id'
  //   } else {
  //     setSelectedRows([]);
  //   }
  // };

  const handleRowSelect = (rowId: number) => {
    setSelectedRows(
      (prev) =>
        prev.includes(rowId)
          ? prev.filter((rowId) => rowId !== rowId) // Deselect if already selected
          : [...prev, rowId] // Select if not selected
    );
  };

  const getAllGym = async () => {
    await axios
      .get(`${urls.baseUrl}/api/gym-owner/${gymId}/Members?limit=30`)
      .then(function (response) {
        console.log("response", response.data.data);
        setnewid(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Technical issue while getting gym details!", {
          position: "top-right",
        });
      });
  };

  // const [gymDetailOneUpdateData, setgymDetailOneUpdateData] = useState([]);

  const onfilterChange = (event: any) => {
    let input = event.target.value;
    if (input) {
      let filteredData = newid?.filter((o: any) =>
        o.email.toLowerCase().includes(input.toLowerCase())
      );
      setnewid(filteredData);
    } else {
      getAllGym();
    }

    console.log("input", input);
  };

  function formatDateManually(isoDateString: string | number | Date) {
    console.log("isoDateString ", isoDateString);
    if (isoDateString) {
      // Convert the input string "20251030" into a Date object
      const isoDateStringFormatted = isoDateString.toString();
      const year = isoDateStringFormatted.slice(0, 4); // Extract year
      const month = isoDateStringFormatted.slice(4, 6); // Extract month
      const day = isoDateStringFormatted.slice(6, 8); // Extract day

      // Return the formatted string as "yyyy/mm/dd"
      return `${year}/${month}/${day}`;
    } else {
      return null;
    }
  }

  // pagination code starts here

  const [page, setPage] = useState(1); // Current page (1-based index)
  const rowsPerPage = 10; // Rows per page (fixed to 10)

  const totalPages = Math.ceil(newid.length / rowsPerPage); // Total pages

  // Get current rows to display based on the current page
  const currentRows = newid.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage); // Update the current page
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        padding: "10px",
        paddingTop: "16px",
      }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={classes.mainHead}>{gymName}</Typography>
          {/* <img src="images/dashboard/sort 1.svg" alt=""/> */}

          {/* <TextField
            id="input-with-icon-textfield"
            size="small"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
          /> */}
        </Box>
        {/* <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#C98630",
            maxWidth: 140,
            margin: " -10px  0  10px 16px",
          }}
          onClick={gotoAddMember}
        >
          Add member
        </Button> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginRight: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ marginLeft: "5px", padding: "10px" }}>
              <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: "#C98630" }}
              >
                Export
              </Button>
              {/* <Button
                size="small"
                style={{
                  marginLeft: "10px",
                  color: "black",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Clear Filter
              </Button> */}
            </Typography>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{ padding: "10px", textAlign: "right" }}
            ></Grid>
            {/* </Grid> */}
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Filter"
              size="small"
              sx={{ maxWidth: 160, margin: " 5px 0 5px 16px" }}
              onChange={onfilterChange}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />{" "}
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#C98630",
                maxWidth: 160,
                margin: " 10px 0 10px 16px",
              }}
              onClick={gotoAddMember}
            >
              Add Member
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>
        <TableContainer sx={classes.tableContainer}>
          <Table sx={{}}>
            <TableHead>
              <TableRow style={{ font: "bold" }}>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={selectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < rows.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  First Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Last Name
                </TableCell>
                {/* <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Full Name
                </TableCell> */}
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Mobile Number
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Member Id
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Package
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 180,
                  }}
                >
                  Joining Package Date
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Gender
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Date Of Birth
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 180,
                  }}
                >
                  Current Package Id
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Current Address
                </TableCell>
                {/* <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Birth Month Date
                </TableCell> */}
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Amount Paid
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 180,
                  }}
                >
                  Actual Package Price
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    minWidth: 160,
                  }}
                >
                  Aadhar Card No
                </TableCell>

                {/* <TableCell sx={{ minWidth: 140 }}>00-00-00</TableCell>
                <TableCell sx={{ minWidth: 140 }}>Active</TableCell>
                <TableCell sx={{ minWidth: 140 }}>Plan</TableCell>
                <TableCell sx={{ minWidth: 140 }}>Noida sector 16</TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  View/Edit
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Delete
                </TableCell>
                {/* <TableCell>
                  <Button
                    sx={{ zIndex: "1" }}
                    onClick={goToGymDetailOneUpdateOne1}
                  >
                    view/delete
                    <EditIcon style={{ color: "white" }} />
                  </Button>
                </TableCell>
                <TableCell>
                  {" "}
                  <Button sx={{ zIndex: "1" }}>
                    {" "}
                    <DeleteOutlineIcon style={{ color: "white" }} />
                  </Button>
                </TableCell> */}
                {/* <TableCell>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: " #00FF80",
                      color: "black",
                      borderRadius: "5px",
                    }}
                    size="small"
                    fullWidth
                  >
                    Enable
                  </Button>
                </TableCell> */}
                {/* <TableCell style={{textAlign:'center'}}>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {newid.map((row: any, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: "white",
                    height: "36px", // Adjust height
                    "& .MuiTableCell-root": {
                      // Adjust padding for all TableCells in this row
                    },
                    "&:hover": {
                      backgroundColor: "#FFDEDE",
                      cursor: "pointer",
                    },
                  }}
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedRows.includes(row.rowId)}
                      onChange={() => handleRowSelect(row.rowId)}
                    />
                  </TableCell> */}
                  {/* <TableCell>
                    <Tooltip title={`${row.name}`} placement="bottom">
                      <Typography> {getFirst15Characters(row.name)}</Typography>
                    </Tooltip>
                  </TableCell> */}
                  <TableCell>{row.firstName}</TableCell>

                  <TableCell>{row.lastName}</TableCell>
                  {/* <TableCell>{row.fullName}</TableCell> */}
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.mobileNumber}</TableCell>
                  <TableCell>{row.memberId}</TableCell>
                  <TableCell>{row.package}</TableCell>
                  <TableCell>
                    {row.status === true ? "enable" : "disable"}
                  </TableCell>

                  <TableCell>
                    {formatDateManually(row.joiningPackageDate)}{" "}
                  </TableCell>
                  <TableCell>{row.gender}</TableCell>
                  <TableCell>{formatDateManually(row.dateOfBirth)}</TableCell>
                  <TableCell>{row.currentPackageId}</TableCell>
                  <TableCell>{row.currentAddress}</TableCell>
                  {/* <TableCell>
                    {" "}
                    {formatDateManually(row.birthMonthDate)}{" "}
                  </TableCell> */}
                  <TableCell>{row.amountPaid}</TableCell>
                  <TableCell>{row.actualPackagePrice}</TableCell>
                  <TableCell>{row.aadharCardNo}</TableCell>

                  <TableCell>
                    <Button
                      onClick={() => goToGymDetailOneUpdateOne(row)}
                      sx={{ zIndex: "1" }}
                    >
                      <EditIcon style={{ color: "rgb(201, 134, 48)" }} />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={handleOpen}>
                      <DeleteOutlineIcon
                        style={{ color: "rgb(201, 134, 48)" }}
                      />
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Box
                          component="main"
                          sx={{
                            padding: "0px !important",
                            flexGrow: 1,
                            p: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Stack>
                            <Grid
                              container
                              style={{
                                backgroundColor: "#C98630",
                                borderRadius: "10px",
                              }}
                            >
                              <Grid item>
                                <Typography
                                  style={{
                                    backgroundColor: " #FFDCAE",
                                    textAlign: "center",
                                    padding: "10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Dashboard Name
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    margin: "5px",
                                  }}
                                >
                                  {/* <ErrorOutlineRoundedIcon style={{color:'white',margin:'0 5px'}}/> */}
                                  <Typography style={{ color: "white" }}>
                                    Are you sure to delete this?
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    margin: "5px",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "#FFDCAE",
                                      padding: "7px 22px",
                                      borderRadius: "10px",
                                      margin: "2px 24px",
                                      color: "black",
                                    }}
                                  >
                                    yes
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: "#FFDCAE",
                                      padding: "7px 22px",
                                      borderRadius: "10px",
                                      margin: "2px 24px",
                                      color: "black",
                                    }}
                                    onClick={goToClientfeedback}
                                  >
                                    No
                                  </Button>
                                  {/* <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>Yes</Typography>
                       <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>No</Typography> */}
                                </Box>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Box>
                      </Box>
                    </Modal>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Pagination
            count={40}
            variant="outlined"
            shape="rounded"
            sx={{ color: "red" }}
          /> */}

          {/* pagination code  starts here*/}

          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              // style={{ marginRight: "8px" }}
              sx={{
                border: "1px solid rgb(201, 134, 48) !important",
                color: "black !important",
                marginRight: "8px",
              }}
            >
              &lt; Previous
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                sx={{
                  border: "1px solid rgb(201, 134, 48) !important",
                  color: "black !important",
                  backgroundColor: "white !important",
                  "&:hover": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:visited": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:focus": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:active": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                }}
                key={index + 1}
                variant={index + 1 === page ? "contained" : "outlined"}
                size="small"
                onClick={() => handlePageChange(index + 1)}
                style={{ marginRight: "4px" }}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              variant="outlined"
              size="small"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
              sx={{
                border: "1px solid rgb(201, 134, 48) !important",
                color: "black !important",
                marginRight: "8px",
              }}
            >
              Next &gt;
            </Button>
          </Box>

          {/* pagination code  ends here*/}

        </Stack>
      </Box>
    </Box>
  );
};

export default GymDetailOneUpdate;
