import React, { FC, useState } from "react";
import styles from "./GymDetailUpdate.module.css";
import MiniDrawer from "../../components/Sidebar";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import gymDetail from "./gymDetail.styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from "react-toastify";
import urls from "../../global/urlConstant";

const classes = gymDetail;

interface EditableGymData {
  aadharCardNo: number;
  actualPackagePrice: number;
  amountPaid: number;
  birthMonthDate: number;
  currentAddress: string;
  currentPackageId: any;
  dateOfBirth: number;
  email: string;
  firstName: string;
  fullName: string;
  gender: string;
  joiningPackageDate: number;
  lastName: string;
  memberId: number;
  mobileNumber: number;
  mypackage: string;
  status: string;
}

const GymDetailOneUpdateEdit: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const receivedData = location.state.row;
  const gymId = location.state.gymId;

  console.log("receivedData", receivedData);

  const [editableData, setEditableData] = useState(receivedData); // Create a local state from props

  const convertDate = (date: Number) => {
    console.log(date);
    if (date) {
      let year = date && date.toString().slice(0, 4);
      let month = date.toString().slice(4, 6);
      let day = date.toString().slice(6, 8);
      return `${year}-${month}-${day}`;
    }
  };
  const [birthMonthDate, setBirthMonthDate] = useState(
    convertDate(receivedData.birthMonthDate)
  );
  const [dateOfBirth, setDateOfBirth] = useState(
    convertDate(receivedData.dateOfBirth)
  );
  const [joiningPackageDate, setJoiningPackageDate] = useState(
    convertDate(receivedData.joiningPackageDate)
  );

  // for name change
  // const handleInputChange =
  //   (field: string) =>
  //   (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  //     setEditableData((prevData: EditableGymData) => ({
  //       ...prevData,
  //       [field]: event.target.value,
  //     }));
  //   };

  const formatDateToYYYYMMDD = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
  };
  const handleInputChange =
    (field: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      let value: any = event.target.value;
      // if (
      //   ["dateOfBirth", "joiningPackageDate", "birthMonthDate"].includes(field)
      // ) {
      //   value = formatDateToYYYYMMDD(value);
      // }
      if ("dateOfBirth" === field) {
        setDateOfBirth(value);
      }
      if ("joiningPackageDate" === field) {
        setJoiningPackageDate(value);
      }
      if ("birthMonthDate" === field) {
        setBirthMonthDate(value);
      }
      console.log("value==", value);
      console.log("field==", field);
      setEditableData((prevData: EditableGymData) => ({
        ...prevData,
        [field]: value,
      }));
    };

  // for unique id change
  const handleUniqueChange = (event: any) => {
    setEditableData({ ...editableData, uniqueId: event.target.value }); // Update name
  };
  // for plan change
  const handlePlanChange = (event: any) => {
    setEditableData({ ...editableData, plan: event.target.value }); // Update name
  };
  // for price change
  const handlePriceChange = (event: any) => {
    setEditableData({ ...editableData, price: event.target.value }); // Update name
  };
  // for total change
  const handleTotalChange = (event: any) => {
    setEditableData({ ...editableData, total: event.target.value }); // Update name
  };

  function goToGymDetail() {
    navigate("/gymDetailOneUpdate");
  }

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    Padding: "10px",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const updatedGymDetails = {
      ...editableData,
      updatedAt: new Date().toISOString(),
      dateOfBirth: formatDateToYYYYMMDD(editableData.dateOfBirth),
      joiningPackageDate: formatDateToYYYYMMDD(editableData.joiningPackageDate),
      birthMonthDate: formatDateToYYYYMMDD(editableData.birthMonthDate),
    };
    console.log("receivedData1 ", editableData);

    try {
      await axios.put(
        `${urls.baseUrl}/api/gym-owner/${gymId}/Members/${editableData._id}`,
        updatedGymDetails
      );
      console.log("Update successful");
      navigate("/gymDetailOneUpdate", { state: { id: gymId } });
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        padding: "10px",
      }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography sx={classes.mainHead}>
                        Client Query
                    </Typography> */}
          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>
        <Stack>
          <Typography
            variant="h5"
            style={{
              fontWeight: "700",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "15px 10px",
            }}
          >
            Gym Detail - Edit / View
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center", margin: "10px", padding: "10px" }}
            >
              {" "}
              <Button onClick={handleOpen}>
                <CloudUploadIcon
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "100px",
                    fontSize: "40px",
                  }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => console.log(event.target.files)}
                        multiple
                      />
                    </Button>

                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<FindReplaceIcon />}
                    >
                      Replace Image
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Image
                    </Button>
                  </Box>
                </Modal>
              </Button>
              <Typography>Upload Image</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}> First Name</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter first name"
                  value={editableData.firstName}
                  onChange={handleInputChange("firstName")}
                />
              </div>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}> Full Name</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter full name"
                  value={editableData.fullName}
                  onChange={handleInputChange("fullName")}
                />
              </div>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Last Name</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter name"
                  value={editableData.lastName}
                  onChange={handleInputChange("lastName")}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Email</label>
                <input
                  type="email"
                  className={styles.inputField}
                  placeholder="Enter email"
                  value={editableData.email}
                  onChange={handleInputChange("email")}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Mobile No.</label>
                <input
                  type="mobileNumber"
                  value={editableData.mobileNumber}
                  onChange={handleInputChange("mobileNumber")}
                  className={styles.inputField}
                  placeholder="Enter number"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Member Id</label>
                <input
                  type="memberId"
                  value={editableData.memberId}
                  onChange={handleInputChange("memberId")}
                  className={styles.inputField}
                  placeholder="Enter member Id"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Package</label>
                <select
                  value={editableData.package}
                  className={styles.selectField}
                  onChange={handleInputChange("package")}
                >
                  <option value="">Select</option>
                  <option value="SILVER">Silver</option>
                  <option value="GOLD">Gold</option>
                  <option value="DIAMOND">Diamond</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Status</label>
                <select
                  value={editableData.status}
                  onChange={handleInputChange("status")}
                  className={styles.selectField}
                >
                  <option value="">Select</option>
                  <option value="true">Enable</option>
                  <option value="false">Disable</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Joining Package Date</label>
                <input
                  type="date"
                  id="joiningPackageDate"
                  value={joiningPackageDate}
                  className={styles.inputField}
                  placeholder="Enter joining Package Date"
                  onChange={handleInputChange("joiningPackageDate")}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Gender</label>
                {/* <input
                  type="text"
                  value={editableData.gender}
                  onChange={handleInputChange("gender")}
                  className={styles.inputField}
                  placeholder="Enter gender"
                /> */}
                <select
                  id="gender"
                  className={styles.inputField}
                  value={editableData.gender}
                  onChange={handleInputChange("gender")}
                >
                  <option value="">Select</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Date Of Birth</label>
                <input
                  type="date"
                  id="dateOfBirth"
                  value={dateOfBirth}
                  className={styles.inputField}
                  placeholder="Enter date of birth"
                  onChange={handleInputChange("dateOfBirth")}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Curent Package Id</label>
                <input
                  type="number"
                  value={editableData.currentPackageId}
                  onChange={handleInputChange("currentPackageId")}
                  className={styles.inputField}
                  placeholder="Enter current Package Id"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Curent Address</label>
                <input
                  type="text"
                  value={editableData.currentAddress}
                  onChange={handleInputChange("currentAddress")}
                  className={styles.inputField}
                  placeholder="Enter current Address"
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Birth Month Date</label>
                <input
                  type="date"
                  id="birthMonthDate"
                  value={birthMonthDate}
                  className={styles.inputField}
                  placeholder="Enter birth month date"
                  onChange={handleInputChange("birthMonthDate")}
                />
              </div>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Amount Paid</label>
                <input
                  type="number"
                  value={editableData.amountPaid}
                  onChange={handleInputChange("amountPaid")}
                  className={styles.inputField}
                  placeholder="Enter amountPaid"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Actual Package Price</label>
                <input
                  type="number"
                  value={editableData.actualPackagePrice}
                  onChange={handleInputChange("actualPackagePrice")}
                  className={styles.inputField}
                  placeholder="Enter actual Package Price"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Aadhar Card No</label>
                <input
                  type="number"
                  value={editableData.aadharCardNo}
                  onChange={handleInputChange("aadharCardNo")}
                  className={styles.inputField}
                  placeholder="Enter aadharCardNo"
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                marginTop: "20px",
                alignItems: "center",
                gap: "200px",
                // border:"2px solid black"
              }}
            >
              {" "}
              <div>
                <button
                  className={styles.cancelButton}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

export default GymDetailOneUpdateEdit;
