import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import axios from "axios";
import urls from "../../global/urlConstant";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure the Toastify styles are imported
import { useNavigate, useParams } from "react-router-dom";

const SetNewPassword: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>(); // Explicit typing for `token`
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (passwordError) setPasswordError(""); // Clear error on input
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!password) {
      setPasswordError("Please enter a new password!");
      return;
    }

    const payload = { password };

    try {
      const response = await axios.patch(
        `${urls.baseUrl}/api/auth/reset-password/${token}`,
        payload
      );
      console.log(response);
      toast.success("Password set successfully!", { position: "top-right" });

      // Navigate after a delay to let the toast display
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      console.error(error);
      toast.error("Technical issue while setting password!", {
        position: "top-right",
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
      bgcolor="#f9f9f9"
    >
      <Typography variant="h4" gutterBottom>
        Set Password
      </Typography>
      <Typography variant="body1" paragraph>
        Enter your new password.
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: 400 }}>
        <TextField
          label="New Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={handleInputChange}
          error={!!passwordError}
          helperText={passwordError}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Update Password
        </Button>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default SetNewPassword;
