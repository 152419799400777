import React, { FC, useState } from "react";
import styles from "./GymDetailUpdate.module.css";
import MiniDrawer from "../../components/Sidebar";
import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import gymDetail from "./gymDetail.styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import urls from "../../global/urlConstant";

interface EditableGymData {
  _id: string;
  address: string;
  contact: string;
  emailAddress: string;
  gymName: string;
  expiresAt: string;
  photo: string;
  plan: string; // Assuming the plan is one of these 3 values
  subscription: string;
  uniqueId: string;
  userId: string;
  website: string;
  isExpiredSoon: boolean;
  createdAt: string;
  updatedAt: string;
}

const classes = gymDetail;

const GymDetailUpdate: FC = () => {
  console.log("inside GymDetailUpdate");
  const location = useLocation();
  const receivedData = location.state;
  console.log("receivedData======>", receivedData);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [expiresAt, setExpiresAt] = useState<number | undefined>(undefined);
  const [editableData, setEditableData] = useState(receivedData);
  const [open, setOpen] = useState(false);

  // Handlers for form inputs
  const handleInputChange =
    (field: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setEditableData((prevData: EditableGymData) => ({
        ...prevData,
        [field]: event.target.value,
      }));
    };

  const convertDate = (date: Number) => {
    // Convert the input to a string and slice it into the required parts
    let year = date.toString().slice(0, 4);
    let month = date.toString().slice(4, 6);
    let day = date.toString().slice(6, 8);

    // Return the formatted date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
  };
  const [expiryDate, setExpiryDate] = useState(
    convertDate(receivedData.expiresAt)
  );

  // console.log(convertDate(date));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formatDateToYYYYMMDD = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();

    const updatedGymDetails = {
      ...editableData,
      updatedAt: new Date().toISOString(),
      expiresAt: formatDateToYYYYMMDD(expiresAt),
    };

    console.log("editableData=====>", editableData);
    console.log("updatedGymDetails====>", updatedGymDetails);

    try {
      await axios.put(
        `${urls.baseUrl}/api/gyms/${updatedGymDetails.uniqueId}`,
        updatedGymDetails
      );
      // console.log("Update successful");
      navigate("/gymDetail");
    } catch (error) {
      // console.error("Update failed", error);
    } finally {
      setLoading(true);
    }
  };

  const handleExpireAtChange = (field: any) => (event: any) => {
    const value = event.target.value;

    console.log("value===>", value);
    // Format the date as YYYYMMDD
    // const formattedDate = value.replace(/-/g, ""); // Remove hyphens
    setExpiryDate(value);
    setExpiresAt(value);
  };

  return loading ? (
    <Loader />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        padding: "10px",
        width: "100%",
      }}
    >
      <MiniDrawer />
      <Stack>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, marginBottom: 2, textAlign: "center" }}
        >
          Gym Detail - Edit / View
        </Typography>

        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ textAlign: "center", margin: "10px", padding: "10px" }}
          >
            {" "}
            <Button onClick={handleOpen}>
              <CloudUploadIcon
                style={{
                  backgroundColor: "#D9D9D9",
                  borderRadius: "100px",
                  fontSize: "40px",
                }}
              />
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => console.log(event.target.files)}
                      multiple
                    />
                  </Button>

                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    startIcon={<FindReplaceIcon />}
                  >
                    Replace Image
                  </Button>
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    startIcon={<DeleteIcon />}
                  >
                    Delete Image
                  </Button>
                </Box>
              </Modal>
            </Button>
            <Typography>Upload Image</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            // border={1}
            lg={6}
            sx={classes.formGroup1}
          >
            <div className={styles.formGroup}>
              <label className={styles.label}>Name of gym</label>
              <input
                type="text"
                value={editableData.gymName}
                className={styles.inputField}
                onChange={handleInputChange("gymName")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Email</label>
              <input
                type="email"
                value={editableData.emailAddress}
                className={styles.inputField}
                onChange={handleInputChange("emailAddress")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Unique Id</label>
              <input
                type="text"
                value={editableData.uniqueId}
                className={styles.inputField}
                disabled
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Address</label>
              <input
                type="text"
                value={editableData.address}
                className={styles.inputField}
                onChange={handleInputChange("address")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Contact</label>
              <input
                type="text"
                value={editableData.contact}
                className={styles.inputField}
                onChange={handleInputChange("contact")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Plan</label>
              <select
                value={editableData.plan}
                className={styles.selectField}
                onChange={handleInputChange("plan")}
              >
                <option value="">Select</option>
                <option value="SILVER">Silver</option>
                <option value="GOLD">Gold</option>
                <option value="DIAMOND">Diamond</option>
              </select>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Expires At</label>
              <input
                type="date"
                value={expiryDate}
                className={styles.inputField}
                onChange={handleExpireAtChange("expiresAt")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Amount</label>
              <input
                type="number"
                value={editableData.amount}
                className={styles.inputField}
                onChange={handleInputChange("amount")}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              marginTop: "20px",
              alignItems: "center",
              gap: "200px",
              // border:"2px solid black"
            }}
          >
            {" "}
            <div>
              <button
                className={styles.cancelButton}
                onClick={() => navigate("/gymDetail")}
              >
                Cancel
              </button>
              <button
                className={styles.saveButton}
                style={{ marginLeft: "10px" }}
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3} sx={{ padding: 2 }}>
          <Grid item xs={12} sx={{ textAlign: "center", marginBottom: 2 }}>
            <Button
              onClick={handleOpen}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CloudUploadIcon
                sx={{
                  backgroundColor: "#D9D9D9",
                  borderRadius: "50%",
                  fontSize: 40,
                }}
              />
              <Typography>Upload Image</Typography>
            </Button>

            <Modal open={open} onClose={handleClose}>
              <Box sx={style}>
                <Button component="label" startIcon={<CloudUploadIcon />}>
                  Upload Image
                  <VisuallyHiddenInput type="file" multiple />
                </Button>
                <Button component="label" startIcon={<FindReplaceIcon />}>
                  Replace Image
                </Button>
                <Button component="label" startIcon={<DeleteIcon />}>
                  Delete Image
                </Button>
              </Box>
            </Modal>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={styles.formGroup}>
              <label className={styles.label}>UniqueId</label>
              <input
                type="text"
                value={editableData.uniqueId}
                className={styles.inputField}
                disabled
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>Name of Gym</label>
              <input
                type="text"
                value={editableData.gymName}
                className={styles.inputField}
                onChange={handleInputChange("gymName")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Address</label>
              <input
                type="text"
                value={editableData.address}
                className={styles.inputField}
                onChange={handleInputChange("address")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Contact No.</label>
              <input
                type="text"
                value={editableData.contact}
                className={styles.inputField}
                onChange={handleInputChange("contact")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Email Address</label>
              <input
                type="email"
                value={editableData.emailAddress}
                className={styles.inputField}
                onChange={handleInputChange("emailAddress")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={styles.formGroup}>
              <label className={styles.label}>Plan</label>
              <select
                value={editableData.plan}
                className={styles.selectField}
                onChange={handleInputChange("plan")}
              >
                <option value="">Select</option>
                <option value="SILVER">Silver</option>
                <option value="GOLD">Gold</option>
                <option value="DIAMOND">Diamond</option>
              </select>
            </div>
          </Grid>

         
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              marginTop: 3,
            }}
          >
            <Button
              className={styles.cancelButton}
              onClick={() => navigate("/gymDetail")}
            >
              Cancel
            </Button>
            <Button className={styles.saveButton} onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid> */}
      </Stack>
    </Box>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default GymDetailUpdate;
