import React, { FC, useState } from "react";
import styles from "./GymDetailUpdate.module.css";
import MiniDrawer from "../../components/Sidebar";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import gymDetail from "./gymDetail.styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { AddRoadRounded } from "@mui/icons-material";
import urls from "../../global/urlConstant";

const classes = gymDetail;

const AddGym: FC = () => {
  const navigate = useNavigate();

  function goToGymDetail() {
    navigate("/gymDetail");
  }

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    Padding: "10px",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // api code
  const [address, setAddress] = useState();
  const [contact, setContact] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [emailError, setEmailError] = useState<string | null>(null);

  const [gymName, setGymName] = useState();
  const [expireAt, setExpiryAt] = useState<number | undefined>(undefined);
  const [plan, setPlan] = useState();
  const [uniqueId, setUniqueId] = useState("");
  const [userId, setUserId] = useState();
  const [website, setWebsite] = useState();
  const [amount, setAmount] = useState();
  const [status, setStatus] = useState();
  const [joiningDate, setJoiningDate] = useState();

  const handleSubmit = async (event: any) => {
    const obj = {
      address: address,
      contact: contact,
      emailAddress: emailAddress,
      gymName: gymName,
      expiresAt: expireAt,
      plan: plan,
      uniqueId: uniqueId,
      userId: userId,
      website: website,
      amount: amount,
    };

    console.log("Main object is:", obj);
    await axios
      .post(`${urls.baseUrl}/api/gyms`, obj)
      .then(function (response) {
        // console.log(response);
        toast.success("Gym Added!", {
          position: "top-right",
        });
        navigate("/gymDetail");
      })
      .catch(function (error) {
        console.log("new error", error.response);
        toast.error(`${error.response.data.message}`, {
          position: "top-right",
        });
      });
    // };
  };

  const handleNameChange = (event: any) => {
    console.log(event.target.value);
    setGymName(event.target.value);
  };


  const [valueContact, setValueContact] = useState('');

  const handleContactChange = (event: any) => {
    let newValue = event.target.value

    if (newValue.length <= 10) {
      setValueContact(newValue);
    }

    // setmobileNumber(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    const email = event.target.value;
    setEmailAddress(email);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError(null);
    }
  };

  // new func

  const formatDateToYYYYMMDD = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
  };

  const handleExpiryDateChange = (event: any) => {
    // console.log("eexpiring date", event.target.value);
    // Convert to number in YYYYMMDD format
    const formattedDate = formatDateToYYYYMMDD(event.target.value);
    console.log("formatted date is:", formattedDate);

    setExpiryAt(formattedDate);
  };
  const handlePlanChange = (event: any) => {
    console.log(event.target.value);
    setPlan(event.target.value);
  };
  const handleAddressChange = (event: any) => {
    console.log(event.target.value);
    setAddress(event.target.value);
  };
  const handleStatusChange = (event: any) => {
    console.log(event.target.value);
    setStatus(event.target.value);
  };
  const handleJoiningDateChange = (event: any) => {
    console.log(event.target.value);
    setJoiningDate(event.target.value);
  };
  const handleuserIdChange = (event: any) => {
    console.log(event.target.value);
    setUserId(event.target.value);
  };
  const handleuniqueIdChange = (event: any) => {
    console.log(event.target.value);
    const uniqueId = "gym-".concat(event.target.value);
    setUniqueId(uniqueId);
  };
  const handleWebsiteChange = (event: any) => {
    console.log(event.target.value);
    setWebsite(event.target.value);
  };

  const handleAmountChange = (event: any) => {
    console.log("event ", event);
    setAmount(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        padding: "10px",
      }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography sx={classes.mainHead}>
                        Client Query
                    </Typography> */}
          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>
        <Stack>
          <Typography
            variant="h5"
            style={{
              fontWeight: "700",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "15px 10px",
            }}
          >
            Add Gym
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center", margin: "10px", padding: "10px" }}
            >
              {" "}
              <Button onClick={handleOpen}>
                <CloudUploadIcon
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "100px",
                    fontSize: "40px",
                  }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => console.log(event.target.files)}
                        multiple
                      />
                    </Button>

                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<FindReplaceIcon />}
                    >
                      Replace Image
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Image
                    </Button>
                  </Box>
                </Modal>
              </Button>
              <Typography>Upload Image</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Name of gym</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter Number"
                  id="name"
                  name="name"
                  onChange={handleNameChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Date of Joining</label>
                <input
                  type="date"
                  id="dateOfjoining"
                  name="dateOfjoining"
                  onChange={handleJoiningDateChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Contact No.</label>
                <input
                  type="number"
                  className={styles.inputField}
                  placeholder="Enter Number"
                  id="contact"
                  name="contact"
                  value={valueContact}
                  onChange={handleContactChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Email</label>
                <input
                  type="Email"
                  className={styles.inputField}
                  placeholder="Enter Email"
                  id="email"
                  name="email"
                  onChange={handleEmailChange}
                />
                {emailError && <p className={styles.errorText}>{emailError}</p>}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Expiry Date</label>
                <input
                  type="date"
                  id="expiryDate"
                  name="expiryDate"
                  onChange={(e) => handleExpiryDateChange(e)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Status</label>
                <select
                  onChange={handleStatusChange}
                  id="status"
                  name="status"
                  className={styles.selectField}
                >
                  <option value="">Select</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Plan</label>
                <select
                  onChange={handlePlanChange}
                  id="plan"
                  name="plan"
                  className={styles.selectField}
                >
                  <option value="">Select</option>
                  <option value="SILVER">Silver</option>
                  <option value="GOLD">Gold</option>
                  <option value="DIAMOND">Diamond</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Address</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter Address"
                  id="address"
                  name="address"
                  value={address}
                  onChange={handleAddressChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>User Id</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter User id"
                  id="userId"
                  name="userId"
                  onChange={handleuserIdChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Unique Id</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter unique id"
                  id="uniqueId"
                  name="uniqueId"
                  onChange={handleuniqueIdChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Website</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter Website"
                  id="website"
                  name="website"
                  onChange={handleWebsiteChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Amount</label>
                <input
                  type="number"
                  className={styles.inputField}
                  onChange={handleAmountChange}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                marginTop: "20px",
                alignItems: "center",
                gap: "200px",
                // border:"2px solid black"
              }}
            >
              {" "}
              <div>
                <button className={styles.cancelButton} onClick={goToGymDetail}>
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                // onSubmit={sumbitHandler}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default AddGym;
