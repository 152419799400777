const clientQuerystyles = {
  mainHead: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#343434",
    width: "203px",
    height: "36px",
    margin: "0 0 20px 16px",
  },

  tableContainer: {
    overflowY: "hidden",
    marginBottom: "20px",
  },

  formGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "15px 0",
    "@media (min-width: 910px)": {
      alignItems: "center",
    },
  },
  formGroup1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "15px 0",
    "@media (min-width: 910px)": {
      alignItems: "end",
    },
  },
};

export default clientQuerystyles;
