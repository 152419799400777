import React, { FC, useState } from "react";
import styles from "../gymDetail/GymDetailUpdate.module.css";
import MiniDrawer from "../../components/Sidebar";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import salesReport from "../salesReport/salesReport.styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from "react-toastify";
import urls from "../../global/urlConstant";

const classes = salesReport;

const AddClient: FC = () => {
  const navigate = useNavigate();

  function goToDashboard() {
    navigate("/dashboard");
  }

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    Padding: "10px",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [uniqueId, setUniqueId] = useState("");
  const [userId, setUserId] = useState();
  const [gymName, setGymName] = useState();
  const [createdAt, setcreatedAt] = useState();
  const [contact, setcontact] = useState();

  const [emailAddress, setemailAddress] = useState();
  const [updatedAt, setupdatedAt] = useState();
  const [status, setstatus] = useState();
  const [plan, setplan] = useState();
  const [address, setaddress] = useState();
  const [website, setwebsite] = useState();
  const [amount, setamount] = useState();
  const [subscription, setsubscription] = useState();

  const handleSubmit = async (event: any) => {
    const obj = {
      address: address,
      contact: contact,
      emailAddress: emailAddress,
      gymName: gymName,
      uniqueId: uniqueId,
      userId: userId,
      website: website,
      createdAt: createdAt,
      updatedAt: updatedAt,
      plan: plan,
      amount: amount,
      subscription: subscription,
      status: status,
    };

    console.log("obj", obj);
    await axios
      .post(`${urls.baseUrl}/api/gyms?isExpired=true`, obj)
      .then(function (response) {
        console.log(response);
        toast.success("Gym Added!", {
          position: "top-right",
        });
        navigate("/dashboard");
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Gym Not Added!", {
          position: "top-right",
        });
      });
    // };
  };

  const handlegymNameChange = (event: any) => {
    console.log(event.target.value);
    setGymName(event.target.value);
  };
  const handlesubscriptionChange = (event: any) => {
    console.log(event.target.value);
    setsubscription(event.target.value);
  };
  const handleamountChange = (event: any) => {
    console.log(event.target.value);
    setamount(event.target.value);
  };
  const handleaddressChange = (event: any) => {
    console.log(event.target.value);
    setaddress(event.target.value);
  };

  const handlesetCreatedAtChange = (event: any) => {
    console.log(event.target.value);
    setcreatedAt(event.target.value);
  };
  const handlesetcontactChange = (event: any) => {
    console.log(event.target.value);
    setcontact(event.target.value);
  };
  const handleemailAddressChange = (event: any) => {
    console.log(event.target.value);
    setemailAddress(event.target.value);
  };

  const handlesetupdatedAtChange = (event: any) => {
    console.log(event.target.value);
    setupdatedAt(event.target.value);
  };
  const handlestatusChange = (event: any) => {
    console.log(event.target.value);
    setstatus(event.target.value);
  };
  const handleplanChange = (event: any) => {
    console.log(event.target.value);
    setplan(event.target.value);
  };

  const handlewebsiteChange = (event: any) => {
    console.log(event.target.value);
    setwebsite(event.target.value);
  };
  const handleuserIdChange = (event: any) => {
    console.log(event.target.value);
    setUserId(event.target.value);
  };
  const handleuniqueIdChange = (event: any) => {
    console.log(event.target.value);
    const uniqueId = "gym-".concat(event.target.value);
    setUniqueId(uniqueId);
  };

  // recieved data modify useState() hooks code starts here

  // recieved data modify useState() hooks code ends here

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 1, display: "flex", justifyContent: "center" }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography sx={classes.mainHead}>
                        Client Query
                    </Typography> */}
          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>

        <Stack>
          <Typography
            variant="h5"
            style={{
              fontWeight: "700",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "15px 10px",
            }}
          >
            Dashboard - Edit / View
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center", margin: "10px", padding: "10px" }}
            >
              {" "}
              <Button onClick={handleOpen}>
                <CloudUploadIcon
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "100px",
                    fontSize: "40px",
                  }}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Image
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event: { target: { files: any } }) =>
                          console.log(event.target.files)
                        }
                        multiple
                      />
                    </Button>

                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<FindReplaceIcon />}
                    >
                      Replace Image
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Image
                    </Button>
                  </Box>
                </Modal>
              </Button>
              <Typography>Upload Image</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              // border={1}
              lg={6}
              sx={classes.formGroup1}
            >
              <div className={styles.formGroup}>
                <label className={styles.label}>Name of gym</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter Number"
                  id="gymName"
                  name="gymName"
                  onChange={handlegymNameChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Date of Joining</label>
                <input
                  type="date"
                  className={styles.inputField}
                  placeholder="Enter Joining date"
                  id="CreatedAt"
                  name="CreatedAt"
                  onChange={handlesetCreatedAtChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Contact No.</label>
                <input
                  type="number"
                  className={styles.inputField}
                  placeholder="Enter Number"
                  id="contact"
                  name="contact"
                  onChange={handlesetcontactChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Email</label>
                <input
                  type="Email"
                  className={styles.inputField}
                  placeholder="Enter Email"
                  id="emailAddress"
                  name="emailAddress"
                  onChange={handleemailAddressChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Expiry date</label>
                <input
                  type="date"
                  className={styles.inputField}
                  placeholder="Enter date"
                  id="updatedAt"
                  name="updatedAt"
                  onChange={handlesetupdatedAtChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label
                  className={styles.label}
                  id="status"
                  onChange={handlestatusChange}
                >
                  Status
                </label>
                <select className={styles.selectField}>
                  <option value="">Select</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label
                  className={styles.label}
                  id="plan"
                  onChange={handleplanChange}
                >
                  Plan
                </label>
                <select className={styles.selectField}>
                  <option value="">Select</option>
                  <option value="PLAN">Silver</option>
                  <option value="PLAN">Gold</option>
                  <option value="PLAN">Diamond</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Website</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter website"
                  id="website"
                  name="website"
                  onChange={handlewebsiteChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Amount</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter website"
                  id="amount"
                  name="amount"
                  onChange={handleamountChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Address</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter website"
                  id="address"
                  name="address"
                  onChange={handleaddressChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>User Id</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter User id"
                  id="userId"
                  name="userId"
                  onChange={handleuserIdChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Unique Id</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter unique id"
                  id="uniqueId"
                  name="uniqueId"
                  onChange={handleuniqueIdChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={classes.formGroup1}>
              <div className={styles.formGroup}>
                <label className={styles.label}>Subscription</label>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter subscription"
                  id="subscription"
                  name="subscription"
                  onChange={handlesubscriptionChange}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                marginTop: "20px",
                alignItems: "center",
                gap: "200px",
                // border:"2px solid black"
              }}
            >
              {" "}
              <div>
                <button className={styles.cancelButton} onClick={goToDashboard}>
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  style={{ marginLeft: "10px" }}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

export default AddClient;
function navigate(arg0: string) {
  throw new Error("Function not implemented.");
}
